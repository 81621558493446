const state = {
    redirect: "/app/bienvenido"
}

const getters = {
    getRoute(state){
        return state.redirect;
    }
}

export default {
    namespaced: true,
    state,
    getters
}