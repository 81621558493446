const state = {
    user: {},
    token: null
}

const mutations = {
    setToken(base, token){
        base.token = token;
        localStorage.setItem('hope', token);
    },
    logout(base){
        base.token = null;
        base.user = {};
        localStorage.removeItem("hope");
    },
    setUser(base, user){
        base.user = user;
    }

}

const getters = {
    isLogin(base){
        if(base.user.id){
            return true;
        }
        return false;
    },
    cghID(base){
        if(base.user.id){
            if(base.user.personaEcosistemas.length < 1){
                return 'x'
            }else{
                const ecosistemas = base.user.personaEcosistemas.filter(z=>z.ecosistema === 3 || z.ecosistema === 2)
                let resultado = 'x'
                ecosistemas.forEach(x=>{
                    resultado = x.referencia;
                })
                return resultado;
            }
        }else{
            return 'x'
        }
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    getters
}