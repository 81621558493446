export default [
    {
        path:"distancia-mensual/encuesta",
        component: () => import(/* webpackChunkName: "about" */ '../views/app/pvs/EncuestaDistanciaMensual.vue')
    },
    {
        path:"distancia-mensual-all/encuesta",
        component: () => import(/* webpackChunkName: "about" */ '../views/app/pvs/EncuestaDistanciaMensualAll.vue')
    },
    {
        path:"distancia-semanal/encuesta",
        component: () => import(/* webpackChunkName: "about" */ '../views/app/pvs/EncuestaDistanciaSemanal.vue')
    },
    {
        path:"cardio/encuesta",
        component: () => import(/* webpackChunkName: "about" */ '../views/app/pvs/EncuestaCardio.vue')
    },
    {
        path:"vida/encuesta",
        component: () => import(/* webpackChunkName: "about" */ '../views/app/pvs/EncuestaVida.vue')
    },
    {
        path:"historial",
        component: () => import(/* webpackChunkName: "about" */ '../views/app/pvs/Historial.vue')
    },
    {
        path:"*",
        component: () => import(/* webpackChunkName: "about" */ '../views/Index.vue')
    }
]