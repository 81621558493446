import Vue from 'vue'
import VueRouter from 'vue-router'

// modulos
import appModule from './app'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Index.vue')
  },
  {
    path: '/hola',
    name: 'Inicio',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/welcome/Bienvenida.vue')
  },
  {
    path: '/login',
    component: () => import('../views/login/Logeo.vue')
  },
  {
    path: '/recover/solicitar',
    component: () => import('../views/login/RecuperarPassword.vue')
  },
  {
    path: '/recover/password',
    component: () => import('../views/login/UpdatePassword.vue')
  },
  {
    path: '/app',
    component: () => import(/* webpackChunkName: "about" */ '../views/app/Index.vue'),
    children: appModule
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "about" */ '../views/Index.vue')
  },
]

const router = new VueRouter({
  //mode: 'history',
  routes
})

export default router
