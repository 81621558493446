import pvsModule from './pvs'
import serviceModule from './servicios'

export default [
    
    {
        path:"/",
        component: () => import(/* webpackChunkName: "about" */ '../views/app/pvs/Inicio.vue'),
    },
    {
        path:"pvs",
        component: () => import(/* webpackChunkName: "about" */ '../views/app/pvs/Redirect.vue'),
        children: pvsModule
    },
    {
        path:"remedios/inicio",
        component: () => import(/* webpackChunkName: "about" */ '../views/app/remedios/Index.vue')
    },
    {
        path:"service",
        component: () => import(/* webpackChunkName: "about" */ '../views/app/servicios/Index.vue'),
        children: serviceModule
    },
    
    {
        path:"*",
        component: () => import(/* webpackChunkName: "about" */ '../views/app/pvs/Inicio.vue'),
    }
]