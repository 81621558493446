import Vue from 'vue'
import Vuex from 'vuex'

// modulos
import login from './login'
import core from './core'
import remedio from './remedio'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    login,
    core,
    remedio
  }
})
