const state = {
    info: [
        {
            alt: 'Aire',
            src:'/img/remedio/aire.jpg',
            video: 'https://www.youtube.com/embed/5aq5b0-afGU',
            url: 'https://www.youtube.com/watch?v=5aq5b0-afGU',
            title: 'EL AIRE PURO',
            text: 'El oxígeno es esencial para la vida. Respire aire puro para mantener una buena salud.'
        },
        {
            alt: 'Luz',
            src:'/img/remedio/luz.jpg',
            video: 'https://www.youtube.com/embed/lIETgXdjetU',
            url: 'https://www.youtube.com/watch?v=lIETgXdjetU',
            title: 'LA LUZ DEL SOL',
            text: 'La vida no puede existir ni funcionar sin la luz solar.'
        },
        {
            alt: 'Agua',
            src:'/img/remedio/agua.jpg',
            title: 'EL AGUA',
            video: 'https://www.youtube.com/embed/xHpy2ulZaPc',
            url: 'https://www.youtube.com/watch?v=xHpy2ulZaPc',
            text: 'El agua en nuestro cuerpo se encuentra tanto en las células de los tejidos como en el espacio que las rodea.'
        },
        {
            alt: 'Ejercicio',
            src:'/img/remedio/ejercicio.jpg',
            video: 'https://www.youtube.com/embed/J4hgfYWvhfE',
            url: 'https://www.youtube.com/watch?v=J4hgfYWvhfE',
            title: 'EL EJERCICIO',
            text: 'Todos sabemos que la actividad física nos hace bien, pero pocos le damos la prioridad  de cuan absolutamente vital es para la conservación de la buena salud.'
        },
        {
            alt: 'Alimentación',
            src:'/img/remedio/nutricion.jpg',
            video: 'https://www.youtube.com/embed/5-PiAIxGxJc',
            url: 'https://www.youtube.com/watch?v=5-PiAIxGxJc',
            title: 'LA ALIMENTACIÓN',
            text: 'Todas las células y tejidos de nuestro cuerpo se forman por el alimento que ingerimos.'
        },
        {
            alt: 'Reposo',
            src:'/img/remedio/reposo.jpg',
            video: 'https://www.youtube.com/embed/rqjmvkgvOSo',
            url: 'https://www.youtube.com/watch?v=rqjmvkgvOSo',
            title: 'EL REPOSO',
            text: 'El descanso promueve la eficiencia mental y física, reanimando las defensas del organismo.'
        },
        {
            alt: 'Temperancia',
            src:'/img/remedio/temperancia.jpg',
            video: 'https://www.youtube.com/embed/fjoWZM6N_xY',
            url: 'https://www.youtube.com/watch?v=fjoWZM6N_xY',
            title: 'LA TEMPERANCIA',
            text: 'La temperancia es el uso moderado e inteligente de todo lo bueno y la abstinencia de todo lo perjudicial.'
        },
        {
            alt: 'Esperanza',
            src:'/img/remedio/confianza.png',
            video: 'https://www.youtube.com/embed/ygFDF9CAEo8',
            url: 'https://www.youtube.com/watch?v=ygFDF9CAEo8',
            title: 'LA ESPERANZA Y CONFIANZA',
            text: 'Si podemos reconocer que en el universo hay inteligencia y propósito, el paso lógico siguiente consiste en buscar a su Creador.'
        },
    ]
}

const getters = {
    
}

export default {
    namespaced: true,
    state,
    getters
}
